import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {format} from 'date-fns'

import DatePicker from '../../../Components/DatePicker'
import Dropbox from '../../../Components/Dropbox'

const dateSelectionTypes = (intl: any) => [
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.thisWeek'}),
    id: 'THIS_WEEK',
  },
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.priorWeek'}),
    id: 'PRIOR_WEEK',
  },
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.thisMonth'}),
    id: 'THIS_MONTH',
  },
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.prirMonth'}),
    id: 'PRIOR_MONTH',
  },
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.thisYear'}),
    id: 'THIS_YEAR',
  },
  {
    title: intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.dateSelection.custom'}),
    id: 'CUSTOM',
  },
]

const FilterComponent = ({handleFilter}: any) => {
  const intl = useIntl()
  const [selectedDate, setSelectedDate] = useState<any>('')

  const [fromDate, setFromDate] = useState<any>(null)
  const [loadFromDate, setLoadFromDate] = useState<any>('')

  const [toDate, setToDate] = useState<any>(null)
  const [loadToDate, setLoadToDate] = useState<any>('')

  const handleFilterChanges = (data: any, key: any) => {
    if (key === 'datePeriod') {
      if (data.datePeriod !== 'CUSTOM') {
        handleFilter(data)
      }
    }
  }

  const handleDateFilterChanges = (data: any) => {
    handleFilter(data)
  }

  return (
    <div className='row col-md-10'>
      <div className='col-md-3 mb-10'>
        <label className='form-label fw-bolder text-dark mb-2 fs-6'>
          {intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.date'})}
        </label>
        <Dropbox
          customClass={'form-control form-control-lg'}
          placeholder={intl.formatMessage({
            id: 'financialAdv.invoiceDetails.filter.date.placeHolder',
          })}
          customSearchValue={''}
          customOnSearch={(e: any) => {}}
          disabledSearch
          onScroll={() => {}}
          setScrollCalled={() => {}}
          currentPageIndex={0}
          menuList={
            dateSelectionTypes &&
            dateSelectionTypes(intl).map((item: any) => {
              return {
                id: item?.id,
                title: item?.title,
              }
            })
          }
          selectedItemData={(item: any) => {
            if (item?.id) {
              setSelectedDate(item?.id)
              handleFilterChanges({datePeriod: item?.id}, 'datePeriod')
            }
          }}
        />
      </div>
      {selectedDate === 'CUSTOM' && (
        <div className='mb-10 col-md-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.fromDate'})}
          </label>
          <DatePicker
            value={fromDate}
            onSelect={(data: any) => {
              if (data) {
                setFromDate(data)
                setLoadFromDate(format(data, 'yyyy-MM-dd'))
                if (toDate !== null && new Date(data) >= new Date(toDate)) {
                  setToDate(null)
                  setLoadToDate('')
                } else if (toDate !== null && !(new Date(data) >= new Date(toDate))) {
                  handleDateFilterChanges({
                    datePeriod: selectedDate,
                    startDate: new Date(format(data, 'yyyy-MM-dd'))?.getTime().toString(),
                    endDate: new Date(loadToDate)?.getTime().toString(),
                  })
                }
              }
            }}
            selected={!!loadFromDate}
            customDate={fromDate}
            disabledDays={new Date('01/01/1980')}
          />
        </div>
      )}

      {selectedDate === 'CUSTOM' && (
        <div className='mb-10 col-md-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'financialAdv.invoiceDetails.filter.toDate'})}
          </label>
          <DatePicker
            value={toDate}
            onSelect={(data: any) => {
              if (data) {
                setToDate(data)
                setLoadToDate(format(data, 'yyyy-MM-dd'))
                handleDateFilterChanges({
                  datePeriod: selectedDate,
                  startDate: new Date(loadFromDate)?.getTime().toString(),
                  endDate: new Date(format(data, 'yyyy-MM-dd'))?.getTime().toString(),
                })
              }
            }}
            selected={!!loadToDate}
            customDate={toDate}
            disabled={!loadFromDate}
            disabledDays={new Date(fromDate)}
          />
        </div>
      )}
    </div>
  )
}

export default FilterComponent
