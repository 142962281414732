import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

import {Header} from '../NewHomePage/Header/Header'
import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import CompanyInvoiceList from './CompanyInvoiceList'

import {AccountantService} from '../../../../services/InvoiceServices/AccountantService'

import './PublicFinancialAdvisor.scss'

const PublicFinancialAdvisor = () => {
  const {search} = useLocation()
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [noDataFound, setNoDataFound] = useState<boolean>(false)
  const [companyList, setCompanyList] = useState<any>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>('')
  const [selectedCompanyCode, setSelectedCompanyCode] = useState<any>('')
  const AccountantServ = new AccountantService()
  const searchParams = new URLSearchParams(search)
  const param = searchParams.get('token')

  useEffect(() => {
    if (param) {
      AccountantServ.getCompanyListOfAccountantbyToken(param)
        .then((response) => {
          setLoading(false)
          setCompanyList(response.data)
        })
        .catch((e: any) => {
          setLoading(false)
          toast.error(e.response.data.message + ' | ' + e.response.data.code)
        })
    } else {
      setNoDataFound(true)
    }
  }, [])

  return (
    <>
      <div className='main-content-home-wrap'>
        <Header />
      </div>
      <div
        className={`main-public-financial-wrap ${
          !noDataFound ? 'db-area-content-wrap' : 'no-data-content-wrap'
        }`}
      >
        {loading && <Loading />}
        {noDataFound && (
          <div className='no-data-wrap'>
            <img style={{width: '25%'}} src='/media/track.png' alt='truck' />
            <h1>{intl.formatMessage({id: 'global.noDataFound'})}</h1>
          </div>
        )}
        {!noDataFound && (
          <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
            <h1>{intl.formatMessage({id: 'financialAdv.title'})}</h1>
          </div>
        )}
        {!noDataFound && (
          <div>
            {!companyList?.length && (
              <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
                {intl.formatMessage({id: 'Carrier.freightPosting.noData'})}
              </div>
            )}

            <DataTableAdvanced
              columns={[
                {
                  Header: 'ID',
                  accessor: 'id',
                },
                {
                  Header: intl.formatMessage({id: 'financialAdv.public.table.name'}),
                  accessor: 'name',
                },
                {
                  Header: intl.formatMessage({id: 'financialAdv.public.table.email'}),
                  accessor: 'email',
                },
                {
                  Header: intl.formatMessage({id: 'financialAdv.public.table.countryCode'}),
                  accessor: 'countryCode',
                },
                {
                  Header: intl.formatMessage({id: 'financialAdv.public.table.phone'}),
                  accessor: 'phone',
                },
                {
                  Header: 'Company Code',
                  accessor: 'companyCode',
                  hidden: true,
                },
              ]}
              editableRows={['']}
              data={
                companyList &&
                companyList?.map((item: any) => {
                  return {
                    id: item?.companyId,
                    name: item?.name ?? '',
                    email: item?.email ?? '',
                    countryCode: item?.countryCode,
                    phone: item?.phone ?? '',
                    companyCode: item?.companyCode ?? '',
                  }
                })
              }
              showDetailBtn={(row: any) => {
                if (row?.values?.companyCode) {
                  setLoading(true)
                  setSelectedCompanyId(row?.values?.id)
                  setSelectedCompanyCode(row?.values?.companyCode)
                }
              }}
              rowProps={(row: any) => {
                if (row?.values?.id === selectedCompanyId) {
                  return {
                    style: {
                      background: '#BCE29E',
                    },
                  }
                }
              }}
              showDetailBtnText={intl.formatMessage({id: 'financialAdv.public.table.showInvoices'})}
              showPaginaton={false}
            />
          </div>
        )}

        {selectedCompanyCode && (
          <div>
            <CompanyInvoiceList companyCode={selectedCompanyCode} setLoading={setLoading} />
          </div>
        )}
      </div>
    </>
  )
}

export default PublicFinancialAdvisor
