import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {Worker, Viewer} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'

import getDateTime from '../../../../utils/common'
import {KTSVG} from '../../../../_metronic/helpers'
import Modal from '../../../Components/Modal'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import FilterComponent from './FilterComponent'

import {AccountantService} from '../../../../services/InvoiceServices/AccountantService'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const CompanyInvoiceList = ({companyCode, setLoading}: any) => {
  const intl = useIntl()
  const [extraFilter, setExtraFilter] = useState<any>({})
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [invoiceList, setInvoiceList] = useState<any>([])
  const AccountantServ = new AccountantService()
  const [selectedInvoiceList, setSelectedInvoiceList] = useState<any>('SENT')
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null)

  const InvoiceList: any = [
    {
      label: intl.formatMessage({id: 'financialAdv.invoiceDetails.public.tab.sent'}),
      value: 'SENT',
    },
    {
      label: intl.formatMessage({id: 'financialAdv.invoiceDetails.public.tab.received'}),
      value: 'RECEIVED',
    },
  ]

  const handleGetInvoicesListOfCompany = (data: any) => {
    AccountantServ.getInvoiceListOfCompany({companyCode: companyCode, ...data})
      .then((response) => {
        setLoading(false)
        setInvoiceList(response?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    handleGetInvoicesListOfCompany({
      invoiceSourceCode: selectedInvoiceList,
      pageIndex: pageMIndex,
      companyCode: companyCode,
    })
  }, [companyCode])

  const handleInvoiceChange = (invoiceValue: any) => {
    setLoading(true)
    setSelectedInvoiceList(invoiceValue)
    setPageMIndex(0)
    handleGetInvoicesListOfCompany({invoiceSourceCode: invoiceValue, pageIndex: 0, ...extraFilter})
  }

  const handlePagination = (pageIndex: any) => {
    setLoading(true)
    setPageMIndex(pageIndex)
    handleGetInvoicesListOfCompany({
      invoiceSourceCode: selectedInvoiceList,
      pageIndex: pageIndex,
      ...extraFilter,
    })
  }

  const handleInvoiceAsPdf = (data: any) => {
    AccountantServ.getInvoiceAsPdf(data)
      .then((response) => {
        const result = URL.createObjectURL(response.data)
        setSelectedInvoice(result)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const defaultLayoutPluginInstance: any = defaultLayoutPlugin()

  return (
    <div className='mt-10'>
      <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
        <h1>{intl.formatMessage({id: 'financialAdv.invoiceDetails.title'})}</h1>
      </div>

      <div className='d-flex justify-content-center mb-7'>
        <div style={{}}>
          <div className='invoice-btn-wrap'>
            <div>
              {InvoiceList &&
                InvoiceList.length > 0 &&
                InvoiceList.map((item: any, index: any) => {
                  return (
                    <button
                      key={index}
                      className={[
                        `btn btn-wrap-content`,
                        selectedInvoiceList === item.value
                          ? 'btn-primary btn-active-content'
                          : null,
                        '',
                      ].join(' ')}
                      onClick={() => {
                        handleInvoiceChange(item?.value)
                      }}
                    >
                      {item.label}
                    </button>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`row ${
            showFilterPanel ? 'justify-content-between' : 'justify-content-end mb-5'
          } `}
        >
          {showFilterPanel && (
            <FilterComponent
              handleFilter={(data: any) => {
                setLoading(true)
                handleGetInvoicesListOfCompany({
                  invoiceSourceCode: selectedInvoiceList,
                  pageIndex: pageMIndex,
                  ...data,
                })
                setExtraFilter(data)
              }}
            />
          )}
          <div className={`col-md-2 d-flex align-items-center justify-content-end mb-5`}>
            <span
              className={`${
                showFilterPanel ? 'col-md-3' : 'col-md-2'
              } menu-icon d-flex justify-content-end`}
            >
              <div
                className={`btn btn-icon btn-active-light-primary btn-custom ${
                  showFilterPanel ? 'menu-button-active' : ''
                }`}
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              >
                <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
              </div>
            </span>
          </div>
        </div>

        {!invoiceList?.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.freightPosting.noData'})}
          </div>
        )}

        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({
                id: 'financialAdv.invoiceDetails.public.table.invoiceNumber',
              }),
              accessor: 'invoicenumber',
            },
            {
              Header: intl.formatMessage({
                id: 'financialAdv.invoiceDetails.public.table.nameSurnameOrCompanyName',
              }),
              accessor: 'nameSurnameOrCompanyName',
            },
            {
              Header: intl.formatMessage({
                id: 'financialAdv.invoiceDetails.public.table.identityOrTaxNumber',
              }),
              accessor: 'identityOrTaxNumber',
            },
            {
              Header: intl.formatMessage({
                id: 'financialAdv.invoiceDetails.public.table.invoiceDate',
              }),
              accessor: 'invoiceDate',
            },
            {
              Header: intl.formatMessage({id: 'financialAdv.invoiceDetails.public.table.status'}),
              accessor: 'status',
            },
          ]}
          editableRows={['']}
          data={
            invoiceList &&
            invoiceList?.map((item: any) => {
              return {
                id: item?.uuid,
                invoicenumber: item?.invoiceNumber ?? '',
                nameSurnameOrCompanyName: item?.nameSurnameOrCompanyName ?? '',
                identityOrTaxNumber: item?.identityOrTaxNumber,
                invoiceDate: item?.invoiceDate ? getDateTime(item?.invoiceDate, intl.locale) : '',
                status: item?.status ?? '',
              }
            })
          }
          showDetailBtn={(row: any) => {
            setLoading(true)
            handleInvoiceAsPdf({
              uuid: row?.values?.id,
              approved: row?.values?.status === 'Onaylanmadı' ? 'N' : 'Y',
              companyCode: companyCode,
            })
          }}
          showDetailBtnText={intl.formatMessage({
            id: 'financialAdv.invoiceDetails.public.table.action.show',
          })}
          setPageMIndex={handlePagination}
          pageMIndex={pageMIndex}
        />
      </div>

      {selectedInvoice && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setSelectedInvoice(null),
          }}
          width='60%'
          height='100%'
          customAll={
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
              <div style={{height: '720px'}}>
                <Viewer fileUrl={selectedInvoice} plugins={[defaultLayoutPluginInstance]} />
              </div>
            </Worker>
          }
        />
      )}
    </div>
  )
}

export default CompanyInvoiceList
