import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class AccountantService {
  createAccountantLoginToken(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/company-accountant-management/public/accountants/tokens',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  getCompanyListOfAccountantbyToken(token: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/company-accountant-management/public/accountants/${token}/companies`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getInvoiceListOfCompany({
    companyCode,
    invoiceSourceCode,
    pageIndex,
    datePeriod,
    startDate,
    endDate,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/invoice-management/public/companies/invoices?companyCode=${companyCode}&searchText&invoiceSourceCode=${invoiceSourceCode}&datePeriod=${
          datePeriod ?? 'THIS_YEAR'
        }&startDate=${startDate ?? ''}&endDate=${
          endDate ?? ''
        }&pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getInvoiceAsPdf({uuid, approved, companyCode}: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/invoice-management/public/companies/${companyCode}/invoices/${uuid}/approved/${approved}/pdf?download=Y`,
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    }

    return axios(config)
  }
}
